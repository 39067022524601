import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('center-screen',{staticStyle:{"background-image":"url(/name-bg.svg)","background-position":"center center"},attrs:{"background-color":"secondary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":"","color":"primary","tile":"","depressed":""},on:{"click":_vm.goToRegister}},[_vm._v(" "+_vm._s(_vm.$t("login.register"))+" ")]),_c(VDivider,{attrs:{"vertical":""}}),_c(VBtn,{attrs:{"text":"","color":"primary","tile":"","depressed":""},on:{"click":_vm.goToLoginByPhone}},[_vm._v(" "+_vm._s(_vm.$t("login.loginByPhone"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","tile":"","depressed":"","loading":_vm.loading},on:{"click":_vm.tryLogin}},[_vm._v(" "+_vm._s(_vm.$t("login.login"))+" ")])]},proxy:true}])},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$t('login.usernameOrEmail'),"name":"login","prepend-icon":"mdi-account-circle","type":"text","dense":""},model:{value:(_vm.model.principal),callback:function ($$v) {_vm.$set(_vm.model, "principal", $$v)},expression:"model.principal"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"id":"password","label":_vm.$t('user.password'),"name":"password","prepend-icon":"mdi-lock","type":_vm.showPassword ? '' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"dense":""},on:{"keyup":_vm.hitEnter,"click:append":_vm.toggleShowPassword},model:{value:(_vm.model.credential),callback:function ($$v) {_vm.$set(_vm.model, "credential", $$v)},expression:"model.credential"}}),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"small":"","color":"primary","text":""},on:{"click":function () { return _vm.$router.push({ name: 'forgot-password' }); }}},[_vm._v("Forgot Password?")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }