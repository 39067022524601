<template>
  <center-screen background-color="secondary"
    style="background-image: url(/name-bg.svg); background-position: center center; "
  >
    <v-form ref="form" v-model="valid" @submit.prevent>
      <v-row>
        <v-col>
          <v-text-field
            v-model="model.principal"
            :label="$t('login.usernameOrEmail')"
            name="login"
            prepend-icon="mdi-account-circle"
            type="text"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="model.credential"
            id="password"
            :label="$t('user.password')"
            name="password"
            prepend-icon="mdi-lock"
            :type="showPassword ? '' : 'password'"
            @keyup="hitEnter"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="toggleShowPassword"
            dense
          />
          <div class="d-flex justify-end">
            <v-btn
              small
              color="primary"
              text
              @click="() => $router.push({ name: 'forgot-password' })"
              >Forgot Password?</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>

    <template v-slot:actions>
      <v-btn text color="primary" tile depressed @click="goToRegister">
        {{ $t("login.register") }}
      </v-btn>
      <v-divider vertical />
      <v-btn text color="primary" tile depressed @click="goToLoginByPhone">
        {{ $t("login.loginByPhone") }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        tile
        depressed
        :loading="loading"
        @click="tryLogin"
      >
        {{ $t("login.login") }}
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions } from "vuex";
import Copyright from "@/common/components/Copyright";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "Login",
  components: { Copyright, CenterScreen },
  data() {
    return {
      valid: false,
      showPassword: false,
      loading: false,
      redirect: "/",
      model: { type: "login", principal: "", credential: "" },
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    hitEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.tryLogin();
      }
    },
    tryLogin() {
      this.$refs.form.validate();
      setTimeout(() => {
        if (this.valid) {
          this.loading = true;
          this.login(this.model)
            .then(() => {
              this.initTheme();
              this.$router.replace({ name: this.homePage });
              this.loading = false;
            })
            .catch(() => (this.loading = false));
        }
      }, 300);
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
    goToLoginByPhone() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
